import {Api} from 'shared/services/axiosConfig';

export interface IOptionField {
  id: number;
  nome: string;
  codigoParaIntegracao: string;
  ordem: number;
}

export interface IValidationFields {
  id: number;
  idCondicaoParaValidacao: number;
  nomeCondicaoParaValidacao: string | null;
  validacaoInicial: string;
  validacaoFinal: string;
  mensagem: string;
}

export interface IPortResponseOptions {
  id: string;
  valor: string;
}

export interface IResponseForm {
  id?: string;
  valor: string;
  valorChecado: boolean;
  idCampoDaFicha: string;
  nomeArquivo?: string;
  conteudoArquivo?: string;
  opcoes: IPortResponseOptions[];
}

export interface IFormFields {
  idCampo: string;
  nomeCampo: string;
  idTipoDeCampo: number;
  nomeTipoDeCampo: string;
  idCampoPredefinido: number;
  nomeCampoPredefinido: string | null;
  textoDeAjuda: string | null;
  obrigatorio: boolean;
  disponivelParaLead: boolean;
  idTipoMascara: number | null;
  mascara: string;
  ativo: boolean;
  opcoes: IOptionField[];
  validacoes: IValidationFields[];
  resposta?: IResponseForm;
  formatarTexto?: string;
}

export interface IForm {
  apresentarNoFormulario: boolean;
  campos: IFormFields[];
  idSecao: string;
  idSecaoPredefinida: number | null;
  maximoColunas: number;
  nomeSecao: string;
  ehFichaComplementar: boolean;
}

export interface ITermoLGPD {
  disponibilizarTermoDeAceiteParaLead: boolean;
  concordaComTermosECondicoes: boolean;
  textoTermoLgpd: string;
}

export interface IPersonalization {
  bannerParaCabecalhoFormulario: string | null;
  extensaoBanner: string | null;
  extensaoLogotipo: string | null;
  idComplementoDaCampanha: number;
  instrucaoParaCabecalhoFormulario: string | null;
  instrucaoParaRodapeFormulario: string | null;
  logotipoParaCabecalhoFormulario: string | null;
  mensagemDeConclusaoFormulario: string | null;
  corBotaoDeConclusaoFormulario: string;
}

export interface IResponseFormSubmit {
  leadId: string;
  linkPagamento: string | null;
}

export interface IPostData {
  idLeadOrigem: number;
  idCampanha: string;
  idOferta: string;
  textoTermoLgpd: string | null;
  concordaComTermosECondicoes: boolean;
  respostas: IResponseForm[];
}

export interface IPutDataLead {
  idCampanha: string;
  idOferta: string;
  idLeadOrigem: number;
  textoTermoLgpd: string | null;
  concordaComTermosECondicoes: boolean;
  respostas: IResponseForm[];
}

export interface IOptions {
  id: string;
  nome: string;
  idEstabelecimento?: string;
  idCurso?: string;
  idCiclo?: string;
}

export interface IOffer {
  id: string;
  complementos1DaOferta: IOptions[];
  complementos2DaOferta: IOptions[];
  idEstabelecimento: string;
  idTurno: string | null;
  idCurso: string;
  idCiclo: string;
  nomeCiclo: string;
  nomeCurso: string;
  nomeTurno: string | null;
}

export interface IShift {
  id: string;
  idCurso: string;
  idCiclo: string;
  idEstabelecimento: string;
  nome: string;
}

export interface ICycle {
  id: string;
  idCurso: string;
  idEstabelecimento: string;
  nome: string;
}

export interface ICourse {
  id: string;
  idEstabelecimento: string;
  nome: string;
}

export interface IEstablishment {
  id: string;
  nome: string;
}

export interface IEditResponse {
  estabelecimentos: IEstablishment[];
  ciclos: ICycle[];
  cursos: ICourse[];
  turnos: IShift[];
  ofertas: IOffer[];
  personalizacao: null;
  secoes: IForm[];
  idCiclo: string;
  idCurso: string;
  idEstabelecimento: string;
  idTurno: string;
  idOferta: string;
  permiteEditar: boolean;
  textoTermoLgpd: string;
  dataHoraAceiteTermoLgpd: string;
  disponibilizarTermoDeAceiteParaLead: boolean;
}
export interface IZipCode {
  linkPesquisaCep: string;
  usaIntegracaoCep: boolean;
}

export interface IInternalResponse {
  estabelecimentos: IEstablishment[];
  cep: IZipCode;
  ciclos: ICycle[];
  cursos: ICourse[];
  turnos: IShift[];
  ofertas: IOffer[];
  personalizacao: null;
  secoes: IForm[];
  idCurso: string | null;
  idEstabelecimento: string | null;
  idEstabelecimentoPreSelecionado: string;
  textoTermoLgpd: string;
  disponibilizarTermoDeAceiteParaLead: boolean;
}

export interface IInternalData {
  sucesso: boolean;
  data: IInternalResponse | string;
}

export interface IData {
  cep: IZipCode;
  secoes: IForm[];
  idCurso: string;
  ciclos: ICycle[];
  turnos: IShift[];
  cursos: ICourse[];
  ofertas: IOffer[];
  idCampanha: string;
  idMetricaGoogleAnalytics: string;
  idEstabelecimento: string;
  personalizacao: IPersonalization;
  estabelecimentos: IEstablishment[];
  idEstabelecimentoPreSelecionado: string;
  textoTermoLgpd: string;
  disponibilizarTermoDeAceiteParaLead: boolean;
}

export interface IDataLead {
  cep: IZipCode;
  idLead: string;
  secoes: IForm[];
  idCurso: string;
  ciclos: ICycle[];
  turnos: IShift[];
  cursos: ICourse[];
  ofertas: IOffer[];
  idCampanha: string;
  idMetricaGoogleAnalytics: string;
  idEstabelecimento: string;
  personalizacao: IPersonalization;
  estabelecimentos: IEstablishment[];
  idEstabelecimentoPreSelecionado: string;
  textoTermoLgpd: string;
  disponibilizarTermoDeAceiteParaLead: boolean;
}

export interface IResponse {
  sucesso: boolean;
  data: IData | string;
}

export interface IResponseLead {
  sucesso: boolean;
  data: IDataLead | string;
}

export interface IFileData {
  id: string;
  conteudo: string;
  nomeOriginal: string;
}

export interface IFile {
  sucesso: boolean;
  data: IFileData;
}

const getData = async (crypto: string): Promise<IResponse | undefined> => {
  try {
    const response = await Api().get<IResponse>(
      `/lead/formulario?parametroCriptografado=${crypto}`,
    );

    return response.data;
  } catch (error) {
    throw new Error('Erro ao Carregar os dados');
  }
};

const getDataLead = async (
  crypto: string,
): Promise<IResponseLead | undefined> => {
  try {
    const response = await Api().get<IResponseLead>(
      `/lead/formulario/lead?parametroCriptografado=${crypto}`,
    );

    return response.data;
  } catch (error) {
    throw new Error('Erro ao Carregar os dados');
  }
};

const getInternalData = async (
  idCampaign: string,
): Promise<IInternalData | undefined> => {
  try {
    const response = await Api().get<IInternalData>(
      `/lead/FormularioInterno?idCampanha=${idCampaign}`,
    );

    return response.data;
  } catch (error) {
    throw new Error('Erro ao Carregar os dados');
  }
};

const getInternalEditData = async (
  idCampaign: string,
  idLead: string,
): Promise<IEditResponse | undefined> => {
  try {
    const response = await Api().get<IEditResponse>(
      `/lead/FormularioComRespostas?idCampanha=${idCampaign}&idLead=${idLead}`,
    );

    return response.data;
  } catch (error) {
    throw new Error('Erro ao Carregar os dados');
  }
};

const putData = async (
  id: string,
  payload: Omit<IPostData, 'idCampanha'>,
): Promise<{status: boolean}> => {
  try {
    await Api().put(`/Lead/${id}`, payload);

    return {status: true};
  } catch (error) {
    throw new Error('Erro ao Carregar os dados');
  }
};

const putDataLead = async (
  id: string,
  payload: Omit<IPutDataLead, 'idCampanha'>,
): Promise<{status: boolean}> => {
  try {
    await Api().put(`/Lead/formulario/lead?idLead=${id}`, payload);

    return {status: true};
  } catch (error) {
    throw new Error('Erro ao Carregar os dados');
  }
};

const postData = async (
  token: string,
  payload: IPostData,
): Promise<IResponseFormSubmit> => {
  try {
    const {data} = await Api().post(
      `/Lead/formulario?recaptchaInput=${token}`,
      payload,
    );

    return data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const postInternalData = async (
  payload: IPostData,
): Promise<{status: boolean}> => {
  try {
    await Api().post('/Lead/FormularioInterno', payload);

    return {status: true};
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const getFile = async (idArquivo: string): Promise<IFile> => {
  try {
    const {data} = await Api().get<IFile>(`/Arquivo/${idArquivo}`);

    return data;
  } catch (error) {
    throw new Error('Erro ao carregar o arquivo.');
  }
};

export const FormService = {
  getInternalEditData,
  postInternalData,
  getInternalData,
  getDataLead,
  putDataLead,
  postData,
  putData,
  getData,
  getFile,
};
