/**
 * Contém variáveis de ambiente para mais fácil utilização
 */
export const Environment = {
  /**
   * Informa a url para as APIs do Central de conversão
   */

  URL_API: location.host.includes('localhost')
    ? 'https://api-dev.gvdasa.com.br/conversao/api/v1'
    : location.host.includes('-dev')
    ? 'https://api-dev.gvdasa.com.br/conversao/api/v1'
    : location.host.includes('-hml')
    ? 'https://api-hml.gvdasa.com.br/conversao/api/v1'
    : 'https://api.gvdasa.com.br/conversao/api/v1',

  URL_ORIGIN_DEBUG: window.location.host.includes('localhost')
    ? 'https://area87gvcollege2-dev.conversao.gvdasa.com.br/'
    : '',
};
