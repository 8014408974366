import React, {
  useRef,
  useMemo,
  Fragment,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  Box,
  Grid,
  Link,
  Button,
  MenuItem,
  IconButton,
  Typography,
  FormControlLabel,
  CircularProgress,
} from '@material-ui/core';
import * as Yup from 'yup';
import {Form} from '@unform/web';
import InputMask from 'react-input-mask';
import {FormHandles} from '@unform/core';
import {format, addHours} from 'date-fns';
import {ObjectShape} from 'yup/lib/object';
import ReCAPTCHA from 'react-google-recaptcha';
import {useNavigate, useParams} from 'react-router-dom';
import {
  CloudUpload,
  Create,
  Delete,
  Info,
  PanoramaSharp,
  Visibility,
} from '@material-ui/icons';
import {
  Header,
  Checkbox,
  TextField,
  InfoTooltip,
  MultiSelect,
  SplashScreen,
} from 'shared/components';
import {useResponse} from 'shared/hooks';
import {
  AvailabilitiesService,
  IScheduleAvailability,
} from 'shared/services/api/ScheduleAvailabilityService';
import stringCapitalize from 'shared/utils/stringCapitalize';
import getValidationErrors from 'shared/utils/getValidationErrors';
import setValidationErrors from 'shared/utils/setValidationErrors';
import {AvailableSchedule} from 'shared/components/AvailableSchedule';
import {
  FormService,
  IFormFields,
  IResponseForm,
  IValidationFields,
} from 'shared/services/api/FormService';
import {AddressService, IAddress} from 'shared/services/api/AddressService';

import {useStyles} from './styles';
import {Alert} from '@material-ui/lab';

interface IInputVisibleProps {
  value?: string;
  visible: boolean;
}

export interface IInputVisible {
  [key: string]: IInputVisibleProps;
}

export interface ICepActive {
  [key: string]: boolean;
}

export const FormPage: React.FC = () => {
  const [recaptcha, setRecaptcha] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [address, setAddress] = useState<IAddress[]>([]);
  const [isInvalideCep, setIsInvalideCep] = useState(false);
  const [cepActive, setCepActive] = useState<ICepActive>({});
  const [inputVisible, setInputVisible] = useState<IInputVisible>({});
  const [formValidation, setFormValidation] = useState({} as ObjectShape);
  const [availabilities, setAvailabilities] = useState<IScheduleAvailability[]>(
    [],
  );
  const [isCheckboxError, setIsCheckboxError] = useState(false);
  const [isRecaptchaError, setIsRecaptchaError] = useState(false);
  const [emailsErrorsId, setEmailsErrorsId] = useState<string[]>([]);
  const [predefined, setPredefined] = useState({
    establishment: '',
    course: '',
    cycle: '',
    shift: '',
    additional1: '',
    additional2: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [isResponseError, setIsResponseError] = useState(false);

  const classes = useStyles();
  const navigate = useNavigate();
  const {
    crypto,
    idLead = '',
    idCampaign = '',
    leadCrypto = '',
  } = useParams<'crypto' | 'idLead' | 'idCampaign' | 'leadCrypto'>();

  const {
    isInternal,
    getData,
    getDataLead,
    getInternalData,
    getInternalEditData,
    postInternalData,
    postData,
    putData,
    putDataLead,
    setCrypto,
    idEstablishment,
    idCourse,
    idCycle,
    idShift,
    form,
    termoLGPD,
    dataHoraAceiteTermoLgpd,
    setIsTermoLGPDAtivo,
    offers,
    personalization,
    course,
    cycle,
    establishment,
    shift,
    loading,
    isError,
    message,
    isEditing,
    permissionEdit,
    useZipCode,
  } = useResponse();

  const formRef = useRef<FormHandles>(null);

  const handleGetAddress = useCallback(
    async (cep: string, index: number) => {
      setIsLoading(true);

      if (!cep.includes('_') && cep.length >= 8) {
        const response = await AddressService.getAddress(cep);

        const addressCopy: IAddress[] = address;
        if (response) {
          addressCopy[index] = response;
          setAddress([...addressCopy]);
          setCepActive((state) => (state = {...state, [index]: true}));

          if (
            addressCopy[index].bairro === '' ||
            addressCopy[index].uf === '' ||
            addressCopy[index].localidade === '' ||
            addressCopy[index].logradouro === ''
          ) {
            setCepActive((state) => (state = {...state, [index]: false}));
          }

          setIsInvalideCep(false);
        } else {
          addressCopy[index] = {
            cep,
            bairro: '',
            logradouro: '',
            localidade: '',
            uf: '',
          };
          setAddress([...addressCopy]);

          setCepActive((state) => (state = {...state, [index]: false}));
          setIsInvalideCep(true);
        }
      }
      setIsLoading(false);
    },
    [address],
  );

  const handleGetFile = useCallback(async (idArquivo: string) => {
    try {
      setIsLoading(true);
      const response = await FormService.getFile(idArquivo);
      if (response.sucesso) {
        const idArquivo: HTMLAnchorElement = document.createElement('a');

        idArquivo.href = `data:application/octet-stream;base64,${response.data.conteudo}`;
        idArquivo.download = response.data.nomeOriginal;
        idArquivo.click();
      } else {
        console.error('Erro ao tentar carregar o arquivo.');
      }
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleChangeRecaptcha = useCallback((value: string | null) => {
    if (value) {
      setRecaptcha(value);
      setIsRecaptchaError(false);
    }
  }, []);

  const handleMaxColumns = useCallback((value: number) => {
    switch (value) {
      case 1:
        return 12;
      case 2:
        return 6;
      case 3:
        return 4;
      default:
        return 12;
    }
  }, []);

  const handleValuePredefined = useCallback(
    (
      idCampoPredefinido: number,
      idCampo: string,
      identificadorCampo: string,
    ): string => {
      switch (idCampoPredefinido) {
        case 2:
          return predefined.establishment;
        case 3:
          return predefined.course;
        case 4:
          return predefined.cycle;
        case 5:
          return predefined.shift;
        case 6:
          return predefined.additional1;
        case 7:
          return predefined.additional2;
        default: {
          const anySelectField = stringCapitalize(identificadorCampo, idCampo);
          return formRef.current?.getFieldValue(anySelectField);
        }
      }
    },
    [
      predefined.course,
      predefined.cycle,
      predefined.establishment,
      predefined.shift,
      predefined.additional1,
      predefined.additional2,
    ],
  );

  const timezone = useMemo(() => {
    const DEFAULT_TIMEZONE = -3;
    // return new Date().getHours() - new Date().getUTCHours();
    return DEFAULT_TIMEZONE;
  }, []);

  const handleValidateEmail = (
    idCampo: string,
    validacoes: IValidationFields[],
  ) => {
    if (emailsErrorsId.some((value) => value === idCampo)) {
      return validacoes.some((value) => value.idCondicaoParaValidacao === 2);
    }
  };

  const handleGetSchedules = useCallback(
    async (idOffer: string) => {
      const response = await AvailabilitiesService.getAvailabilities(idOffer);

      const availabilities = response?.map(
        (availability: IScheduleAvailability) =>
          ({
            ...availability,
            iniciaEm: format(
              addHours(new Date(availability.iniciaEm), timezone),
              "yyyy-MM-dd'T'HH:mm",
            ),
            terminaEm: format(
              addHours(new Date(availability.terminaEm), timezone),
              "yyyy-MM-dd'T'HH:mm",
            ),
          } as IScheduleAvailability),
      );

      if (availabilities) {
        setAvailabilities([...availabilities]);
      }
    },
    [timezone],
  );

  const handleSetPredefined = useCallback(
    (
      target,
      idPredefinido: number,
      identificadorCampo: string,
      idCampo: string,
    ) => {
      switch (idPredefinido) {
        case 2:
          {
            const establ = stringCapitalize(identificadorCampo, idCampo);
            formRef.current?.setFieldValue(establ, target.value);
            setPredefined(
              (state) =>
                (state = {
                  ...state,
                  establishment: String(target.value),
                }),
            );
          }
          break;
        case 3:
          {
            const course = stringCapitalize(identificadorCampo, idCampo);
            formRef.current?.setFieldValue(course, target.value);
            setPredefined(
              (state) =>
                (state = {
                  ...state,
                  course: String(target.value),
                }),
            );
          }
          break;
        case 4: {
          const cycle = stringCapitalize(identificadorCampo, idCampo);
          formRef.current?.setFieldValue(cycle, target.value);
          setPredefined(
            (state) =>
              (state = {
                ...state,
                cycle: String(target.value),
              }),
          );
          break;
        }
        case 5:
          {
            const shift = stringCapitalize(identificadorCampo, idCampo);
            formRef.current?.setFieldValue(shift, target.value);
            setPredefined(
              (state) =>
                (state = {
                  ...state,
                  shift: String(target.value),
                }),
            );
          }
          break;
        case 6: {
          const additional1 = stringCapitalize(identificadorCampo, idCampo);
          formRef.current?.setFieldValue(additional1, target.value);
          setPredefined(
            (state) =>
              (state = {
                ...state,
                additional1: String(target.value),
              }),
          );
          break;
        }
        case 7: {
          const additional2 = stringCapitalize(identificadorCampo, idCampo);
          formRef.current?.setFieldValue(additional2, target.value);
          setPredefined(
            (state) =>
              (state = {
                ...state,
                additional2: String(target.value),
              }),
          );
          break;
        }
        default: {
          const defaultSelectValue = stringCapitalize(
            identificadorCampo,
            idCampo,
          );
          formRef.current?.setFieldValue(defaultSelectValue, target.value);
          break;
        }
      }
    },
    [],
  );

  const handleGetAddressValues = useCallback(
    (id: number, index: number) => {
      if (cepActive) {
        switch (id) {
          case 9:
            return address[index]?.logradouro || '';
          case 12:
            return address[index]?.uf || '';
          case 13:
            return address[index]?.localidade || '';
          case 14:
            return address[index]?.bairro || '';
          default:
            return undefined;
        }
      }
      return undefined;
    },
    [cepActive, address],
  );

  const handleSetAdressValues = useCallback(
    (idPredefinido: number, index: number, target) => {
      const addressCopy: IAddress[] = address;

      switch (idPredefinido) {
        case 9:
          addressCopy[index].logradouro = target.value;
          break;
        case 12:
          addressCopy[index].uf = target.value;
          break;
        case 13:
          addressCopy[index].localidade = target.value;
          break;
        case 14:
          addressCopy[index].bairro = target.value;
          break;
        default:
          break;
      }

      setAddress([...addressCopy]);
    },
    [address],
  );

  const initialData = useMemo(() => {
    let aux = {};
    if (form) {
      form.map((item) => {
        item?.campos?.map((field) => {
          if (field.ativo) {
            if (field.idCampoPredefinido === 2 && idEstablishment) {
              aux = {
                ...aux,
                [stringCapitalize(field.nomeCampo, field.idCampo)]:
                  idEstablishment,
              };
            } else if (field.idCampoPredefinido === 3 && idCourse) {
              aux = {
                ...aux,
                [stringCapitalize(field.nomeCampo, field.idCampo)]: idCourse,
              };
            } else if (field.resposta) {
              if (
                field.idCampoPredefinido === 4 &&
                field?.resposta?.opcoes[0]?.valor
              ) {
                setPredefined(
                  (state) =>
                    (state = {
                      ...state,
                      cycle: String(field.resposta?.opcoes[0]?.valor),
                    }),
                );

                aux = {
                  ...aux,
                  [stringCapitalize(field.nomeCampo, field.idCampo)]:
                    field.resposta.opcoes[0].valor,
                };
              } else if (
                field.idCampoPredefinido === 5 &&
                field?.resposta?.opcoes[0]?.valor
              ) {
                setPredefined(
                  (state) =>
                    (state = {
                      ...state,
                      shift: String(field.resposta?.opcoes[0]?.valor),
                    }),
                );

                aux = {
                  ...aux,
                  [stringCapitalize(field.nomeCampo, field.idCampo)]:
                    field.resposta.opcoes[0].valor,
                };
              }
              if (field.idCampo === field.resposta.idCampoDaFicha) {
                switch (field.idTipoDeCampo) {
                  case 2:
                    aux = {
                      ...aux,
                      [stringCapitalize(field.nomeCampo, field.idCampo)]:
                        field.resposta.valorChecado,
                    };
                    break;
                  case 4:
                    aux = {
                      ...aux,
                      [stringCapitalize(field.nomeCampo, field.idCampo)]: '',
                    };
                    break;
                  case 5: {
                    if (field.idCampoPredefinido === 6) {
                      const optionsLength =
                        field.resposta?.opcoes.length > 0
                          ? field.resposta?.opcoes.length - 1
                          : 0;
                      setPredefined(
                        (state) =>
                          (state = {
                            ...state,
                            additional1: String(
                              field.resposta?.opcoes[optionsLength]?.valor ||
                                '',
                            ),
                          }),
                      );
                    }
                    if (field.idCampoPredefinido === 7) {
                      const optionsLength =
                        field.resposta?.opcoes.length > 0
                          ? field.resposta?.opcoes.length - 1
                          : 0;
                      setPredefined(
                        (state) =>
                          (state = {
                            ...state,
                            additional2: String(
                              field.resposta?.opcoes[optionsLength]?.valor,
                            ),
                          }),
                      );
                    }
                    const optionsLength =
                      field.resposta?.opcoes.length > 0
                        ? field.resposta?.opcoes.length - 1
                        : 0;
                    aux = {
                      ...aux,
                      [stringCapitalize(field.nomeCampo, field.idCampo)]:
                        field.resposta.opcoes[optionsLength]?.valor || '',
                    };
                    break;
                  }
                  case 6:
                    if (field.resposta.opcoes)
                      aux = {
                        ...aux,
                        [stringCapitalize(field.nomeCampo, field.idCampo)]:
                          field.resposta.opcoes.map((i) => i.valor),
                      };
                    break;
                  default:
                    aux = {
                      ...aux,
                      [stringCapitalize(field.nomeCampo, field.idCampo)]:
                        field.resposta.valor,
                    };
                    break;
                }
              }
            }
          }
        });
      });
    }

    return aux;
  }, [form, idEstablishment, idCourse]);

  const courseFiltered = useMemo(
    () =>
      course.filter((i) => i.idEstabelecimento === predefined.establishment),
    [course, predefined.establishment],
  );

  const cycleFiltered = useMemo(
    () =>
      cycle.filter(
        (i) =>
          i.idCurso === predefined.course &&
          i.idEstabelecimento === predefined.establishment,
      ),
    [cycle, predefined.course, predefined.establishment],
  );

  const idOffer = useMemo(() => {
    if (predefined.shift) {
      if (
        cycleFiltered.some((cycle) => cycle.nome !== null) &&
        predefined.cycle.length > 0
      ) {
        return offers.find(
          (i) =>
            (i.idEstabelecimento === predefined.establishment ||
              i.idEstabelecimento === idEstablishment) &&
            (i.idCurso === predefined.course || i.idCurso === idCourse) &&
            i.idCiclo === predefined.cycle &&
            i.idTurno === predefined.shift,
        )?.id;
      } else {
        return offers.find(
          (i) =>
            (i.idEstabelecimento === predefined.establishment ||
              i.idEstabelecimento === idEstablishment) &&
            (i.idCurso === predefined.course || i.idCurso === idCourse) &&
            i.idTurno === predefined.shift,
        )?.id;
      }
    } else {
      if (
        cycleFiltered.some((cycle) => cycle.nome !== null) &&
        predefined.cycle.length > 0
      ) {
        return offers.find(
          (i) =>
            (i.idEstabelecimento === predefined.establishment ||
              i.idEstabelecimento === idEstablishment) &&
            (i.idCurso === predefined.course || i.idCurso === idCourse) &&
            i.idCiclo === predefined.cycle,
        )?.id;
      } else {
        return offers.find(
          (i) =>
            (i.idEstabelecimento === predefined.establishment ||
              i.idEstabelecimento === idEstablishment) &&
            (i.idCurso === predefined.course || i.idCurso === idCourse),
        )?.id;
      }
    }
  }, [
    offers,
    predefined.cycle,
    predefined.course,
    predefined.establishment,
    predefined.shift,
    idEstablishment,
    idCourse,
  ]);
  const offerSelected = useMemo(() => {
    return offers.find((item) => item.id === idOffer);
  }, [idOffer, offers]);

  const shiftFiltered = useMemo(() => {
    if (
      cycleFiltered.some((cycle) => cycle.nome !== null) &&
      predefined.cycle.length > 0
    ) {
      return shift.filter(
        (i) =>
          i.idCiclo === predefined.cycle &&
          i.idCurso === predefined.course &&
          i.idEstabelecimento === predefined.establishment,
      );
    } else {
      return shift.filter(
        (i) =>
          i.idCurso === predefined.course &&
          i.idEstabelecimento === predefined.establishment,
      );
    }
  }, [shift, predefined.cycle, predefined.course, predefined.establishment]);

  const handleTermoLGPDCheckboxValidate = () => {
    if (!termoLGPD.concordaComTermosECondicoes) {
      setIsCheckboxError(true);
    } else {
      setIsCheckboxError(false);
    }
  };

  const handleOptPredefined = useCallback(
    (field: IFormFields) => {
      switch (field.idCampoPredefinido) {
        case 2:
          return establishment;
        case 3:
          return courseFiltered;
        case 4:
          return cycleFiltered;
        case 5:
          return shiftFiltered;
        case 6:
          return offerSelected?.complementos1DaOferta || [];
        case 7:
          return offerSelected?.complementos2DaOferta || [];
        default:
          return field.opcoes;
      }
    },
    [
      establishment,
      courseFiltered,
      cycleFiltered,
      shiftFiltered,
      offerSelected?.complementos1DaOferta,
      offerSelected?.complementos2DaOferta,
    ],
  );

  const handleSubmitRequest = async (respostas: IResponseForm[]) => {
    let response = null;
    try {
      if (idOffer) {
        if (isInternal) {
          if (isEditing) {
            response = await putData(idLead, {
              idLeadOrigem: 0,
              idOferta: idOffer,
              respostas,
              concordaComTermosECondicoes:
                termoLGPD.concordaComTermosECondicoes,
              textoTermoLgpd: termoLGPD.textoTermoLgpd,
            });
          } else {
            response = await postInternalData({
              idLeadOrigem: 1,
              idOferta: idOffer,
              respostas,
              concordaComTermosECondicoes:
                termoLGPD.concordaComTermosECondicoes,
              textoTermoLgpd: termoLGPD.textoTermoLgpd,
            });
          }
        } else if (leadCrypto) {
          response = await putDataLead(idLead, {
            idOferta: idOffer,
            idLeadOrigem: 0,
            respostas,
            concordaComTermosECondicoes: termoLGPD.concordaComTermosECondicoes,
            textoTermoLgpd: termoLGPD.textoTermoLgpd,
          });
        } else {
          response = await postData(recaptcha, {
            idLeadOrigem: 2,
            idOferta: idOffer,
            respostas,
            concordaComTermosECondicoes: termoLGPD.concordaComTermosECondicoes,
            textoTermoLgpd: termoLGPD.textoTermoLgpd,
          });
        }
      }
      navigate('/confirmacao', {state: response});
    } catch (error: any) {
      if (typeof error === 'object') {
        setIsResponseError(true);
        if (error.message.includes('Error: ')) {
          setErrorMessage(error.message.replace('Error: ', ''));
        } else {
          setErrorMessage(error.message);
        }

        setTimeout(() => {
          setIsResponseError(false);
        }, 5000);
      }
    }
  };

  const handleOnSubmit = async (data: any) => {
    try {
      formRef.current?.setErrors({});

      const respostas: IResponseForm[] = [];
      let shiftName = '';
      let scheduleUsage;

      form.map((item) =>
        item.campos.map((field) => {
          console.log(respostas);
          if (field.idTipoDeCampo === 1) {
            scheduleUsage = availabilities.filter(
              (i) =>
                `${format(
                  new Date(i.iniciaEm),
                  'dd/MM/yyyy HH:mm',
                )} às ${format(new Date(i.terminaEm), 'HH:mm')}` ===
                data[stringCapitalize(field.nomeCampo, field.idCampo)],
            )[0]?.idDisponibilidadeDeAgenda;

            if (!scheduleUsage) {
              setSubmitted(true);
              return;
            }
          }

          if (field.idCampoPredefinido === 5) {
            shiftName = stringCapitalize(field.nomeCampo, field.idCampo);
          }

          const fieldIdentifier = stringCapitalize(
            field.nomeCampo,
            field.idCampo,
          );
          const fieldValue = formRef.current?.getFieldValue(fieldIdentifier);
          respostas.push({
            idCampoDaFicha: String(field.idCampo),
            valor:
              field.idTipoDeCampo === 7 ||
              field.idTipoDeCampo === 5 ||
              field.idTipoDeCampo === 8 ||
              field.idTipoDeCampo === 9 ||
              field.idTipoDeCampo === 10 ||
              field.idTipoDeCampo === 3
                ? data[stringCapitalize(field.nomeCampo, field.idCampo)]
                : field.idTipoDeCampo === 1
                ? availabilities.filter(
                    (i) =>
                      `${format(
                        new Date(i.iniciaEm),
                        'dd/MM/yyyy HH:mm',
                      )} às ${format(new Date(i.terminaEm), 'HH:mm')}` ===
                      data[stringCapitalize(field.nomeCampo, field.idCampo)],
                  )[0].idDisponibilidadeDeAgenda
                : field.idTipoDeCampo === 4 &&
                  !inputVisible[field.idCampo].visible
                ? inputVisible[field.idCampo].value
                : '',
            valorChecado:
              field.idTipoDeCampo === 2
                ? data[stringCapitalize(field.nomeCampo, field.idCampo)]
                : false,
            nomeArquivo:
              field.idTipoDeCampo === 4 &&
              !!data[stringCapitalize(field.nomeCampo, field.idCampo)]
                ? data[stringCapitalize(field.nomeCampo, field.idCampo)].name
                : field.idTipoDeCampo === 4 &&
                  !inputVisible[field.idCampo].visible
                ? field.resposta?.nomeArquivo
                : '',
            conteudoArquivo:
              field.idTipoDeCampo === 4 &&
              !!data[stringCapitalize(field.nomeCampo, field.idCampo)]
                ? data[
                    `${stringCapitalize(field.nomeCampo, field.idCampo)}-file`
                  ]
                : '',
            opcoes:
              field.idTipoDeCampo === 6
                ? data[stringCapitalize(field.nomeCampo, field.idCampo)]?.map(
                    (opt: string) => {
                      return {id: opt};
                    },
                  )
                : fieldValue === false
                ? []
                : field.idTipoDeCampo === 5
                ? [
                    {
                      id: fieldValue,
                    },
                  ]
                : [],
          });
        }),
      );

      setIsRecaptchaError(recaptcha === '');

      if (shiftFiltered.length === 0) {
        delete formValidation[shiftName];
      }

      const schema = Yup.object().shape(formValidation);
      await schema.validate(data, {
        abortEarly: false,
      });

      if (!recaptcha && !isInternal) {
        if (termoLGPD.disponibilizarTermoDeAceiteParaLead && !isEditing) {
          if (!termoLGPD.concordaComTermosECondicoes) {
            setIsCheckboxError(true);
          }
        }
        return;
      }
      setSubmitted(false);

      if (termoLGPD.disponibilizarTermoDeAceiteParaLead && !isEditing) {
        if (!termoLGPD.concordaComTermosECondicoes) {
          setIsCheckboxError(true);
        } else {
          if (emailsErrorsId.length === 0) {
            handleSubmitRequest(respostas);
          }
        }
      } else {
        if (isEditing) {
          setIsTermoLGPDAtivo(true);
        }
        if (emailsErrorsId.length === 0) {
          handleSubmitRequest(respostas);
        }
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err as Yup.ValidationError);
        formRef.current?.setErrors(errors);

        handleTermoLGPDCheckboxValidate();
      }
    }
  };

  const handleFormatText = useCallback(
    (textValue: string, formatType: string) => {
      switch (formatType) {
        case 'uppercase':
          return textValue.toUpperCase();
        case 'lowercase':
          return textValue.toLowerCase();
        case 'titlecase':
          return textValue.replace(/\w\S*/g, (txt: string) => {
            return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
          });
        case 'sentensecase':
          return textValue.replace(/(^\w{1}|\.\s*\w{1})/gi, (txt: string) => {
            return txt.toUpperCase();
          });

        default:
          return textValue;
      }
    },
    [],
  );

  const handleChange = useCallback(() => {
    form.map((item) => {
      item.campos
        .filter((field) => field.idTipoDeCampo === 4)
        .map((field) => {
          const nomeCampo = stringCapitalize(field.nomeCampo, field.idCampo);
          const value = formRef.current?.getFieldValue(nomeCampo);

          if (value) {
            const reader = new FileReader();

            reader.onload = () => {
              const {result} = reader;

              formRef.current?.setFieldValue(
                `${nomeCampo}-file`,
                String(result).split(',')[1],
              );
            };

            reader.readAsDataURL(value);
          }
        });
      item.campos
        .filter(
          (field) => field.idTipoDeCampo === 9 || field.idTipoDeCampo === 10,
        )
        .map((field) => {
          const nomeCampo = stringCapitalize(field.nomeCampo, field.idCampo);
          let value = formRef.current?.getFieldValue(nomeCampo);
          if (
            value &&
            field.validacoes.some(
              (validacao) => validacao.idCondicaoParaValidacao === 2,
            )
          ) {
            value = value.replaceAll(' ', '');
            const especialCharVerification = /[^a-zA-Z0-9._+@-]/;

            if (especialCharVerification.test(value.replace('@', ''))) {
              if (!emailsErrorsId.some((value) => value === field.idCampo)) {
                setEmailsErrorsId((prev) => [...prev, field.idCampo]);
              }
            } else {
              setEmailsErrorsId((prev) =>
                prev.filter((value) => value !== field.idCampo),
              );
            }
            value = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

            formRef.current?.setFieldValue(nomeCampo, value);
          }

          if (value && field.formatarTexto) {
            formRef.current?.setFieldValue(
              nomeCampo,
              handleFormatText(value, field.formatarTexto || ''),
            );
          }
        });

      item.campos
        .filter((field) => field.idTipoDeCampo === 3)
        .map((field) => {
          const nomeCampo = stringCapitalize(field.nomeCampo, field.idCampo);
          const value = formRef.current?.getFieldValue(nomeCampo);
          const newValue = handleFormatInputTextData(value as string);
          formRef.current?.setFieldValue(nomeCampo, newValue);
        });
    });
  }, [form, emailsErrorsId, handleFormatText]);

  const handleDataFormat = (data: string) => {
    if (!data || data === '0001-01-01 00:00:00.0000000') {
      return 'sem registros';
    }
    const [date, time] = data.split('T');
    const [year, month, day] = date.split('-');
    const [hour, min] = time.split(':');
    const response = `${day}/${month}/${year} - ${hour}:${min}`;
    return response;
  };

  const handleFormatInputTextData = (inputData: string) => {
    const [year, month, day] = inputData.split('-');
    const formattedInputData = `${year}-${month}-${day}`;
    if (inputData.length > 10) {
      const formattedInputDataEdited = `${year.substring(
        0,
        year.length - 1,
      )}-${month}-${day}`;
      return formattedInputDataEdited;
    }
    return formattedInputData;
  };

  const handleTermoLGDPTextFormatter = (text: string) => {
    return text ? text.replace(/(<([^>]+)>)/gi, '') : '';
  };

  const createDefaultNameId = (nome: string) => {
    // Remover caracteres não alfabéticos e converter para minúsculas
    nome = nome.replace(/[^a-zA-Z ]/g, '').toLowerCase();

    // Dividir o nome em partes
    const partes = nome.split(' ');

    // Padronizar cada parte
    const partesPadronizadas = partes.map((parte, index) => {
      // Para o primeiro nome, manter em minúsculas
      if (index === 0) {
        return parte;
      } else {
        // Para as outras partes, capitalizar a primeira letra
        return parte.charAt(0).toUpperCase() + parte.slice(1);
      }
    });

    // Juntar as partes padronizadas
    return partesPadronizadas.join('');
  };

  const handleDocumentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;

    if (fileList && fileList.length > 0) {
      const bytes = fileList[0].size;
      const fileSize = (bytes / (1024 * 1024)).toFixed(1);

      if (Number(fileSize) >= 5) {
        const nomePadronizado = createDefaultNameId(
          event.target.parentNode?.querySelector('fieldset span')
            ?.textContent || '',
        );

        const id = event.target.getAttribute('id');

        const nomeFinal = nomePadronizado + id;

        formRef.current?.setErrors({
          [nomeFinal]: 'Arquivo deve ter no máximo 5MB',
        });

        event.target.value = '';
        return;
      } else {
        formRef.current?.setErrors({});
      }
    }
  };

  useEffect(() => {
    if (form) {
      setFormValidation(setValidationErrors(form, inputVisible));
    }
  }, [form, inputVisible]);

  useEffect(() => {
    if (crypto) {
      setCrypto(crypto);
      getData(crypto);
    } else if (idCampaign && idLead) {
      getInternalEditData(idCampaign, idLead);
    } else if (leadCrypto) {
      setCrypto(leadCrypto);
      getDataLead(leadCrypto);
    } else {
      getInternalData(idCampaign);
    }
  }, [
    getData,
    getInternalEditData,
    getInternalData,
    setCrypto,
    crypto,
    idCampaign,
    idLead,
  ]);

  useEffect(() => {
    if (idEstablishment)
      setPredefined(
        (state) => (state = {...state, establishment: idEstablishment}),
      );
    if (idCourse)
      setPredefined((state) => (state = {...state, course: idCourse}));
    if (idCycle) setPredefined((state) => (state = {...state, cycle: idCycle}));
    if (idShift) setPredefined((state) => (state = {...state, shift: idShift}));
  }, [idCourse, idCycle, idEstablishment, idShift]);

  useEffect(() => {
    if (predefined.establishment !== idEstablishment) {
      setPredefined(
        (state) =>
          (state = {
            ...state,
            cycle: '',
            shift: '',
          }),
      );
    }
  }, [idEstablishment, predefined.establishment]);

  useEffect(() => {
    if (predefined.course !== idCourse) {
      setPredefined(
        (state) =>
          (state = {
            ...state,
            cycle: '',
            shift: '',
          }),
      );
    }
  }, [idCourse, predefined.course]);

  useEffect(() => {
    if (idOffer) {
      handleGetSchedules(idOffer);
    }
  }, [idOffer, handleGetSchedules]);

  useEffect(() => {
    form.map((i) =>
      i.campos.map((field) => {
        if (field.idTipoDeCampo === 4) {
          if (field.resposta?.valor) {
            setInputVisible(
              (state) =>
                (state = {
                  ...state,
                  [field.idCampo]: {
                    visible: false,
                    value: field.resposta?.valor,
                  },
                }),
            );
          } else {
            setInputVisible(
              (state) =>
                (state = {
                  ...state,
                  [field.idCampo]: {
                    visible: true,
                    value: '',
                  },
                }),
            );
          }
        }
      }),
    );
  }, [form]);

  if (loading) {
    return <SplashScreen />;
  }

  if (message && message.length > 0) {
    return <SplashScreen message={message} />;
  }

  if (isError) {
    return <SplashScreen error />;
  }

  return (
    <Box maxWidth="100%">
      {isLoading && (
        <div className={classes.overlay}>
          <CircularProgress />
        </div>
      )}

      {isResponseError && (
        <Alert
          variant="filled"
          severity="error"
          style={{
            width: 'fit-content',
            position: 'fixed',
            right: 24,
            top: 16,
            zIndex: 90,
            marginLeft: 16,
          }}
          onClick={() => setIsResponseError(false)}>
          {errorMessage}
        </Alert>
      )}

      {!isInternal && <Header personalization={personalization} />}

      <Box marginTop={2} padding={3}>
        <Form
          ref={formRef}
          onSubmit={handleOnSubmit}
          initialData={initialData}
          onChange={handleChange}>
          <Grid container spacing={3}>
            {!isInternal && personalization.instrucaoParaCabecalhoFormulario && (
              <Box
                width="100%"
                textAlign="center"
                dangerouslySetInnerHTML={{
                  __html:
                    personalization.instrucaoParaCabecalhoFormulario.replaceAll(
                      'class',
                      'className',
                    ),
                }}
              />
            )}

            {form?.map((item, indexSec) => (
              <Fragment key={item.idSecao}>
                {item.campos.length > 0 &&
                  item.apresentarNoFormulario &&
                  item.campos.filter(
                    (i) => i.ativo && (i.disponivelParaLead || isInternal),
                  ).length > 0 && (
                    <Grid item xs={12}>
                      {item.ehFichaComplementar ? (
                        <Box display={'flex'} alignItems={'center'}>
                          <Typography variant="h5">
                            <strong>{item.nomeSecao}</strong>
                          </Typography>
                          <Box marginLeft={0.7} marginTop={0.5}>
                            <Typography variant="body1">
                              (Ficha Complementar)
                            </Typography>
                          </Box>
                        </Box>
                      ) : (
                        <>
                          <Typography variant="h5">
                            <strong>{item.nomeSecao}</strong>
                          </Typography>
                        </>
                      )}
                    </Grid>
                  )}
                {item.campos?.map((field) => (
                  <Fragment key={field.idCampo}>
                    {field.ativo &&
                      (field.disponivelParaLead || isInternal) &&
                      field.idTipoDeCampo === 1 && (
                        <Grid
                          key={field.idCampo}
                          item
                          xs={12}
                          sm={handleMaxColumns(item.maximoColunas)}>
                          <AvailableSchedule
                            label={
                              field.obrigatorio
                                ? `${field.nomeCampo} *`
                                : field.nomeCampo
                            }
                            error={field.obrigatorio && submitted}
                            helperText={
                              field.obrigatorio &&
                              submitted &&
                              'Campo requerido.'
                            }
                            disabled={!permissionEdit}
                            name={stringCapitalize(
                              field.nomeCampo,
                              field.idCampo,
                            )}
                            availabilities={availabilities}
                            cycleFiltered={cycleFiltered}
                            predefinedCycle={predefined.cycle}
                            predefinedCourse={predefined.course}
                          />
                        </Grid>
                      )}
                    {field.ativo &&
                      (field.disponivelParaLead || isInternal) &&
                      field.idTipoDeCampo === 2 && (
                        <Grid key={field.idCampo} item xs={12} sm={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name={stringCapitalize(
                                  field.nomeCampo,
                                  field.idCampo,
                                )}
                                disabled={!permissionEdit}
                                color="primary"
                              />
                            }
                            label={
                              <>
                                {field.obrigatorio
                                  ? `${field.nomeCampo} *`
                                  : field.nomeCampo}{' '}
                                {field.textoDeAjuda && (
                                  <InfoTooltip title={field.textoDeAjuda} />
                                )}
                              </>
                            }
                          />
                        </Grid>
                      )}
                    {field.ativo &&
                      (field.disponivelParaLead || isInternal) &&
                      field.idTipoDeCampo === 3 && (
                        <Grid
                          key={field.idCampo}
                          item
                          xs={12}
                          sm={handleMaxColumns(item.maximoColunas)}>
                          <TextField
                            id={String(field.idCampo)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              inputProps: {
                                min: '0001-01-01',
                                max: '9999-12-31',
                              },
                            }}
                            disabled={!permissionEdit}
                            label={
                              field.obrigatorio
                                ? `${field.nomeCampo} *`
                                : field.nomeCampo
                            }
                            name={stringCapitalize(
                              field.nomeCampo,
                              field.idCampo,
                            )}
                            type="date"
                            helper={
                              field.textoDeAjuda
                                ? field.textoDeAjuda
                                : undefined
                            }
                          />
                        </Grid>
                      )}
                    {field.ativo &&
                      (field.disponivelParaLead || isInternal || leadCrypto) &&
                      field.idTipoDeCampo === 4 && (
                        <Grid
                          item
                          xs={12}
                          key={field.idCampo}
                          alignItems="center"
                          sm={handleMaxColumns(item.maximoColunas)}>
                          {(leadCrypto || isEditing) &&
                          !inputVisible[field.idCampo].visible ? (
                            <Grid
                              wrap="nowrap"
                              container
                              alignItems="center"
                              xs={12}
                              spacing={1}>
                              <Grid item xs={12} md={12}>
                                <TextField
                                  onChange={handleDocumentChange}
                                  fullWidth
                                  id={String(field.idCampo)}
                                  disabled
                                  InputLabelProps={{shrink: true}}
                                  label={
                                    field.obrigatorio
                                      ? `${field.nomeCampo} *`
                                      : field.nomeCampo
                                  }
                                  name={`${stringCapitalize(
                                    field.nomeCampo,
                                    field.idCampo,
                                  )}-edit`}
                                  value={field.resposta?.nomeArquivo}
                                  InputProps={{
                                    inputProps: {
                                      accept: 'image/*,application/pdf',
                                      style: {
                                        cursor: 'pointer',
                                      },
                                    },
                                    endAdornment: (
                                      <InfoTooltip title="PNG/JPEG/GIF/PDF de no máximo 5MB">
                                        <IconButton
                                          disabled
                                          color="primary"
                                          component="span"
                                          aria-label="upload">
                                          <CloudUpload />
                                        </IconButton>
                                      </InfoTooltip>
                                    ),
                                  }}
                                />
                              </Grid>
                              <Grid>
                                <IconButton
                                  color="primary"
                                  component="span"
                                  disabled={!permissionEdit}
                                  onClick={() => {
                                    setInputVisible(
                                      (state) =>
                                        (state = {
                                          ...state,
                                          [field.idCampo]: {
                                            visible: true,
                                            value: '',
                                          },
                                        }),
                                    );
                                  }}>
                                  <Delete />
                                </IconButton>
                              </Grid>
                              <Grid>
                                <IconButton
                                  color="primary"
                                  component="span"
                                  disabled={!permissionEdit}
                                  onClick={() => {
                                    setInputVisible(
                                      (state) =>
                                        (state = {
                                          ...state,
                                          [field.idCampo]: {
                                            visible: true,
                                            value: '',
                                          },
                                        }),
                                    );
                                  }}>
                                  <Create />
                                </IconButton>
                              </Grid>
                              {leadCrypto ? (
                                <></>
                              ) : (
                                <Grid>
                                  <IconButton
                                    color="primary"
                                    component="span"
                                    disabled={!permissionEdit}
                                    onClick={() =>
                                      field.resposta?.valor &&
                                      handleGetFile(field.resposta.valor)
                                    }>
                                    <Visibility />
                                  </IconButton>
                                </Grid>
                              )}
                            </Grid>
                          ) : (
                            <>
                              <TextField
                                onChange={handleDocumentChange}
                                type="file"
                                disabled={!permissionEdit}
                                id={String(field.idCampo)}
                                InputLabelProps={{shrink: true}}
                                label={
                                  field.obrigatorio
                                    ? `${field.nomeCampo} *`
                                    : field.nomeCampo
                                }
                                name={stringCapitalize(
                                  field.nomeCampo,
                                  field.idCampo,
                                )}
                                helper={
                                  field.textoDeAjuda
                                    ? field.textoDeAjuda
                                    : undefined
                                }
                                InputProps={{
                                  inputProps: {
                                    accept: 'image/*,application/pdf',
                                    style: {
                                      cursor: 'pointer',
                                    },
                                  },
                                  endAdornment: (
                                    <InfoTooltip title="PNG/JPEG/GIF/PDF de no máximo 5MB">
                                      <IconButton
                                        color="primary"
                                        component="span"
                                        aria-label="upload">
                                        <CloudUpload />
                                      </IconButton>
                                    </InfoTooltip>
                                  ),
                                }}
                              />
                              <TextField
                                style={{display: 'none'}}
                                disabled
                                id={`${String(field.idCampo)}-file`}
                                name={`${stringCapitalize(
                                  field.nomeCampo,
                                  field.idCampo,
                                )}-file`}
                              />
                            </>
                          )}
                        </Grid>
                      )}
                    {field.ativo &&
                      (field.disponivelParaLead || isInternal || leadCrypto) &&
                      field.idTipoDeCampo === 5 && (
                        <Grid
                          key={field.idCampo}
                          item
                          xs={12}
                          sm={handleMaxColumns(item.maximoColunas)}>
                          {idEstablishment && field.idCampoPredefinido === 2 ? (
                            <TextField
                              name={stringCapitalize(
                                field.nomeCampo,
                                field.idCampo,
                              )}
                              label={
                                field.obrigatorio
                                  ? `${field.nomeCampo} *`
                                  : field.nomeCampo
                              }
                              disabled={!permissionEdit}
                              helper={
                                field.textoDeAjuda
                                  ? field.textoDeAjuda
                                  : undefined
                              }
                              value={predefined.establishment}
                              onChange={({target}) => {
                                setPredefined(
                                  (state) =>
                                    (state = {
                                      ...state,
                                      establishment: target.value,
                                      course: target.value,
                                      cycle: '',
                                      shift: '',
                                    }),
                                );
                              }}
                              select>
                              {handleOptPredefined(field)?.map((opt) => (
                                <MenuItem key={opt.id} value={opt.id}>
                                  {opt.nome}
                                </MenuItem>
                              ))}
                            </TextField>
                          ) : idCourse && field.idCampoPredefinido === 3 ? (
                            <TextField
                              name={stringCapitalize(
                                field.nomeCampo,
                                field.idCampo,
                              )}
                              label={
                                field.obrigatorio
                                  ? `${field.nomeCampo} *`
                                  : field.nomeCampo
                              }
                              disabled={!permissionEdit}
                              helper={
                                field.textoDeAjuda
                                  ? field.textoDeAjuda
                                  : undefined
                              }
                              value={predefined.course}
                              onChange={({target}) =>
                                setPredefined(
                                  (state) =>
                                    (state = {
                                      ...state,
                                      course: target.value,
                                      cycle: '',
                                      shift: '',
                                    }),
                                )
                              }
                              select>
                              {handleOptPredefined(field)?.map((opt) => (
                                <MenuItem key={opt.id} value={opt.id}>
                                  {opt.nome}
                                </MenuItem>
                              ))}
                            </TextField>
                          ) : (idShift && field.idCampoPredefinido === 3) ||
                            leadCrypto ? (
                            <TextField
                              name={stringCapitalize(
                                field.nomeCampo,
                                field.idCampo,
                              )}
                              disabled={
                                handleOptPredefined(field)?.length === 0 ||
                                !permissionEdit
                              }
                              label={
                                field.obrigatorio &&
                                handleOptPredefined(field)?.length > 0
                                  ? `${field.nomeCampo} *`
                                  : field.nomeCampo
                              }
                              helper={
                                field.textoDeAjuda
                                  ? field.textoDeAjuda
                                  : undefined
                              }
                              onChange={({target}) =>
                                setPredefined(
                                  (state) =>
                                    (state = {
                                      ...state,
                                      shift: target.value,
                                    }),
                                )
                              }
                              select>
                              {handleOptPredefined(field)?.map((opt) => (
                                <MenuItem key={opt.id} value={opt.id}>
                                  {opt.nome}
                                </MenuItem>
                              ))}
                            </TextField>
                          ) : (
                            <TextField
                              name={stringCapitalize(
                                field.nomeCampo,
                                field.idCampo,
                              )}
                              disabled={
                                (field.idCampoPredefinido === 4 &&
                                  cycleFiltered.every(
                                    (cycle) => cycle.nome === null,
                                  )) ||
                                handleOptPredefined(field)?.length === 0 ||
                                !permissionEdit
                              }
                              label={
                                field.idCampoPredefinido === 4 &&
                                cycleFiltered.every(
                                  (cycle) => cycle.nome === null,
                                ) ? (
                                  <i>Nenhum ciclo para ser selecionado</i>
                                ) : field.obrigatorio &&
                                  handleOptPredefined(field)?.length > 0 ? (
                                  `${field.nomeCampo} *`
                                ) : (
                                  field.nomeCampo
                                )
                              }
                              helper={
                                field.textoDeAjuda
                                  ? field.textoDeAjuda
                                  : undefined
                              }
                              value={handleValuePredefined(
                                field.idCampoPredefinido,
                                field.idCampo,
                                field.nomeCampo,
                              )}
                              onChange={({target}) => {
                                handleSetPredefined(
                                  target,
                                  field.idCampoPredefinido,
                                  field.nomeCampo,
                                  field.idCampo,
                                );
                              }}
                              select>
                              {handleOptPredefined(field)?.map((opt) => (
                                <MenuItem key={opt.id} value={opt.id}>
                                  {opt.nome}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        </Grid>
                      )}
                    {field.ativo &&
                      (field.disponivelParaLead || isInternal) &&
                      field.idTipoDeCampo === 6 && (
                        <Grid
                          key={field.idCampo}
                          item
                          xs={12}
                          sm={handleMaxColumns(item.maximoColunas)}>
                          <MultiSelect
                            id={String(field.idCampo)}
                            label={
                              field.obrigatorio
                                ? `${field.nomeCampo} *`
                                : field.nomeCampo
                            }
                            disabled={!permissionEdit}
                            helper={
                              field.textoDeAjuda
                                ? field.textoDeAjuda
                                : undefined
                            }
                            name={stringCapitalize(
                              field.nomeCampo,
                              field.idCampo,
                            )}>
                            {handleOptPredefined(field)?.map((opt) => (
                              <MenuItem key={opt.id} value={opt.id}>
                                {opt.nome}
                              </MenuItem>
                            ))}
                          </MultiSelect>
                        </Grid>
                      )}
                    {field.ativo &&
                      (field.disponivelParaLead || isInternal) &&
                      field.idTipoDeCampo === 7 && (
                        <Grid
                          key={field.idCampo}
                          item
                          xs={12}
                          sm={handleMaxColumns(item.maximoColunas)}>
                          {!isEditing &&
                          field.mascara &&
                          field.idTipoMascara ? (
                            <>
                              <InputMask
                                defaultValue={field.resposta?.valor}
                                mask={field.mascara}
                                id={String(field.idCampo)}
                                disabled={!permissionEdit}
                                onBlur={({target}) =>
                                  field.idCampoPredefinido === 8 &&
                                  handleGetAddress(target.value, indexSec)
                                }>
                                {() => (
                                  <TextField
                                    type="number"
                                    inputProps={{
                                      min: '1.0',
                                      step: '0.1',
                                    }}
                                    id={String(field.idCampo)}
                                    defaultValue={field.resposta?.valor}
                                    label={
                                      field.obrigatorio
                                        ? `${field.nomeCampo} *`
                                        : field.nomeCampo
                                    }
                                    disabled={!permissionEdit}
                                    helper={
                                      field.textoDeAjuda
                                        ? field.textoDeAjuda
                                        : undefined
                                    }
                                    name={stringCapitalize(
                                      field.nomeCampo,
                                      field.idCampo,
                                    )}>
                                    {handleOptPredefined(field)?.map((opt) => (
                                      <MenuItem key={opt.id} value={opt.id}>
                                        {opt.nome}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                )}
                              </InputMask>
                            </>
                          ) : (
                            <TextField
                              type="number"
                              inputProps={{
                                min: '1',
                                step: '1',
                                max: '99',
                              }}
                              id={String(field.idCampo)}
                              defaultValue={field.resposta?.valor}
                              label={
                                field.obrigatorio
                                  ? `${field.nomeCampo} *`
                                  : field.nomeCampo
                              }
                              disabled={!permissionEdit}
                              helper={
                                field.textoDeAjuda
                                  ? field.textoDeAjuda
                                  : undefined
                              }
                              name={stringCapitalize(
                                field.nomeCampo,
                                field.idCampo,
                              )}>
                              {handleOptPredefined(field)?.map((opt) => (
                                <MenuItem key={opt.id} value={opt.id}>
                                  {opt.nome}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        </Grid>
                      )}
                    {field.ativo &&
                      (field.disponivelParaLead || isInternal) &&
                      field.idTipoDeCampo === 8 && (
                        <Grid
                          key={field.idCampo}
                          item
                          xs={12}
                          sm={handleMaxColumns(item.maximoColunas)}>
                          {!isEditing &&
                          field.mascara &&
                          field.idTipoMascara ? (
                            <>
                              <InputMask
                                defaultValue={field.resposta?.valor}
                                mask={field.mascara}
                                id={String(field.idCampo)}
                                disabled={!permissionEdit}
                                onBlur={({target}) =>
                                  field.idCampoPredefinido === 8 &&
                                  handleGetAddress(target.value, indexSec)
                                }>
                                {() => (
                                  <TextField
                                    type="number"
                                    inputProps={{
                                      min: '1.0',
                                      step: '0.1',
                                      max: '3.0',
                                    }}
                                    id={String(field.idCampo)}
                                    defaultValue={field.resposta?.valor}
                                    label={
                                      field.obrigatorio
                                        ? `${field.nomeCampo} *`
                                        : field.nomeCampo
                                    }
                                    disabled={!permissionEdit}
                                    helper={
                                      field.textoDeAjuda
                                        ? field.textoDeAjuda
                                        : undefined
                                    }
                                    name={stringCapitalize(
                                      field.nomeCampo,
                                      field.idCampo,
                                    )}>
                                    {handleOptPredefined(field)?.map((opt) => (
                                      <MenuItem key={opt.id} value={opt.id}>
                                        {opt.nome}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                )}
                              </InputMask>
                            </>
                          ) : (
                            <TextField
                              type="number"
                              inputProps={{
                                min: '1.0',
                                step: '0.1',
                                max: '3.0',
                              }}
                              id={String(field.idCampo)}
                              defaultValue={field.resposta?.valor}
                              label={
                                field.obrigatorio
                                  ? `${field.nomeCampo} *`
                                  : field.nomeCampo
                              }
                              disabled={!permissionEdit}
                              helper={
                                field.textoDeAjuda
                                  ? field.textoDeAjuda
                                  : undefined
                              }
                              name={stringCapitalize(
                                field.nomeCampo,
                                field.idCampo,
                              )}>
                              {handleOptPredefined(field)?.map((opt) => (
                                <MenuItem key={opt.id} value={opt.id}>
                                  {opt.nome}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        </Grid>
                      )}
                    {field.ativo &&
                      (field.disponivelParaLead || isInternal) &&
                      field.idTipoDeCampo === 9 && (
                        <Grid
                          key={field.idCampo}
                          item
                          xs={12}
                          sm={handleMaxColumns(item.maximoColunas)}>
                          {!isEditing &&
                          field.mascara &&
                          field.idTipoMascara ? (
                            <>
                              <InputMask
                                defaultValue={field.resposta?.valor}
                                mask={field.mascara}
                                id={String(field.idCampo)}
                                disabled={!permissionEdit}
                                onBlur={({target}) =>
                                  field.idCampoPredefinido === 8 &&
                                  handleGetAddress(target.value, indexSec)
                                }>
                                {() => (
                                  <TextField
                                    label={
                                      field.obrigatorio
                                        ? `${field.nomeCampo} *`
                                        : field.nomeCampo
                                    }
                                    helper={
                                      field.textoDeAjuda
                                        ? field.textoDeAjuda
                                        : field.idCampoPredefinido === 8 &&
                                          isInvalideCep &&
                                          !cepActive[indexSec]
                                        ? 'CEP inválido'
                                        : handleValidateEmail(
                                            field.idCampo,
                                            field.validacoes,
                                          )
                                        ? 'Digito inválido'
                                        : undefined
                                    }
                                    name={stringCapitalize(
                                      field.nomeCampo,
                                      field.idCampo,
                                    )}
                                    disabled={!permissionEdit}
                                    isError={handleValidateEmail(
                                      field.idCampo,
                                      field.validacoes,
                                    )}
                                  />
                                )}
                              </InputMask>
                              {field.idCampoPredefinido === 8 &&
                                useZipCode.usaIntegracaoCep && (
                                  <Link
                                    target="_blank"
                                    className={classes.zipCode}
                                    href={useZipCode.linkPesquisaCep}>
                                    Não sei informar meu CEP
                                  </Link>
                                )}
                            </>
                          ) : Object.keys(cepActive).length &&
                            (field.idCampoPredefinido === 9 ||
                              field.idCampoPredefinido === 12 ||
                              field.idCampoPredefinido === 13 ||
                              field.idCampoPredefinido === 14) ? (
                            <TextField
                              id={String(field.idCampo)}
                              InputLabelProps={{shrink: true}}
                              disabled={cepActive[indexSec] || !permissionEdit}
                              label={
                                field.obrigatorio
                                  ? `${field.nomeCampo} *`
                                  : field.nomeCampo
                              }
                              value={handleGetAddressValues(
                                field.idCampoPredefinido,
                                indexSec,
                              )}
                              onChange={(e) => {
                                handleSetAdressValues(
                                  field.idCampoPredefinido,
                                  indexSec,
                                  e.target,
                                );
                              }}
                              helper={
                                field.textoDeAjuda
                                  ? field.textoDeAjuda
                                  : handleValidateEmail(
                                      field.idCampo,
                                      field.validacoes,
                                    )
                                  ? 'Digito inválido'
                                  : undefined
                              }
                              name={stringCapitalize(
                                field.nomeCampo,
                                field.idCampo,
                              )}
                              isError={handleValidateEmail(
                                field.idCampo,
                                field.validacoes,
                              )}
                            />
                          ) : isEditing && field.idTipoMascara ? (
                            <>
                              <InputMask
                                mask={field.mascara}
                                id={String(field.idCampo)}
                                disabled={!permissionEdit}
                                defaultValue={field.resposta?.valor}
                                onBlur={({target}) =>
                                  field.idCampoPredefinido === 8 &&
                                  handleGetAddress(target.value, indexSec)
                                }>
                                {() => (
                                  <TextField
                                    disabled={!permissionEdit}
                                    label={
                                      field.obrigatorio
                                        ? `${field.nomeCampo} *`
                                        : field.nomeCampo
                                    }
                                    helper={
                                      field.textoDeAjuda
                                        ? field.textoDeAjuda
                                        : field.idCampoPredefinido === 8 &&
                                          isInvalideCep &&
                                          !cepActive[indexSec]
                                        ? 'CEP inválido'
                                        : handleValidateEmail(
                                            field.idCampo,
                                            field.validacoes,
                                          )
                                        ? 'Digito inválido'
                                        : undefined
                                    }
                                    name={stringCapitalize(
                                      field.nomeCampo,
                                      field.idCampo,
                                    )}
                                    isError={handleValidateEmail(
                                      field.idCampo,
                                      field.validacoes,
                                    )}
                                  />
                                )}
                              </InputMask>
                              {field.idCampoPredefinido === 8 &&
                                useZipCode.usaIntegracaoCep && (
                                  <Link
                                    target="_blank"
                                    className={classes.zipCode}
                                    href={useZipCode.linkPesquisaCep}>
                                    Não sei informar meu CEP
                                  </Link>
                                )}
                            </>
                          ) : (
                            <>
                              <TextField
                                id={String(field.idCampo)}
                                disabled={!permissionEdit}
                                label={
                                  field.obrigatorio
                                    ? `${field.nomeCampo} *`
                                    : field.nomeCampo
                                }
                                onBlur={({target}) =>
                                  field.idCampoPredefinido === 8 &&
                                  handleGetAddress(target.value, indexSec)
                                }
                                helper={
                                  field.textoDeAjuda
                                    ? field.textoDeAjuda
                                    : field.idCampoPredefinido === 8 &&
                                      isInvalideCep &&
                                      !cepActive[indexSec]
                                    ? 'CEP inválido'
                                    : handleValidateEmail(
                                        field.idCampo,
                                        field.validacoes,
                                      )
                                    ? 'Digito inválido'
                                    : undefined
                                }
                                name={stringCapitalize(
                                  field.nomeCampo,
                                  field.idCampo,
                                )}
                                error={
                                  (field.idCampoPredefinido === 8 &&
                                    isInvalideCep) ||
                                  (field.obrigatorio && submitted)
                                }
                                isError={handleValidateEmail(
                                  field.idCampo,
                                  field.validacoes,
                                )}
                              />
                              {field.idCampoPredefinido === 8 &&
                                useZipCode.usaIntegracaoCep && (
                                  <Link
                                    target="_blank"
                                    className={classes.zipCode}
                                    href={useZipCode.linkPesquisaCep}>
                                    Não sei informar meu CEP
                                  </Link>
                                )}
                            </>
                          )}
                        </Grid>
                      )}
                    {field.ativo &&
                      (field.disponivelParaLead || isInternal) &&
                      field.idTipoDeCampo === 10 && (
                        <Grid key={field.idCampo} item xs={12} sm={12}>
                          <TextField
                            id={String(field.idCampo)}
                            label={
                              field.obrigatorio
                                ? `${field.nomeCampo} *`
                                : field.nomeCampo
                            }
                            helper={
                              field.textoDeAjuda
                                ? field.textoDeAjuda
                                : undefined
                            }
                            name={stringCapitalize(
                              field.nomeCampo,
                              field.idCampo,
                            )}
                            disabled={!permissionEdit}
                            multiline
                            rows={3}
                          />
                        </Grid>
                      )}
                  </Fragment>
                ))}
              </Fragment>
            ))}
            {termoLGPD.disponibilizarTermoDeAceiteParaLead && (
              <>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    justifyContent: 'center',
                  }}>
                  <Box marginBottom={3}>
                    <Typography variant="h5">
                      <strong>Termos e condições</strong>
                    </Typography>
                  </Box>
                  <TextField
                    id={'Termo'}
                    fullWidth
                    label={'Termo'}
                    name={'Termo'}
                    isError={isCheckboxError}
                    disabled={!permissionEdit}
                    helper={
                      isCheckboxError && !isEditing
                        ? 'Campo requerido.'
                        : undefined
                    }
                    multiline
                    rows={5}
                    InputProps={{readOnly: true}}
                    value={handleTermoLGDPTextFormatter(
                      termoLGPD.textoTermoLgpd,
                    )}
                  />
                  {!isEditing && (
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}>
                      <Checkbox
                        disabled={!permissionEdit}
                        name="23"
                        readOnly={isEditing}
                        isChecked={termoLGPD.concordaComTermosECondicoes}
                        onClick={() => {
                          setIsCheckboxError(false);
                          setIsTermoLGPDAtivo(
                            !termoLGPD.concordaComTermosECondicoes,
                          );
                        }}
                      />
                      <Typography variant="body1">
                        <strong>Concordo com os termos e condições</strong>
                      </Typography>
                    </Box>
                  )}

                  {isEditing && termoLGPD.disponibilizarTermoDeAceiteParaLead && (
                    <Box marginTop={2} marginBottom={1} width={170}>
                      <TextField
                        InputProps={{readOnly: true}}
                        fullWidth
                        name="data e hora"
                        label="Data e hora aceite"
                        value={handleDataFormat(dataHoraAceiteTermoLgpd)}
                      />
                    </Box>
                  )}
                </Grid>
              </>
            )}

            {!isInternal ? (
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  marginTop={3}>
                  <ReCAPTCHA
                    sitekey="6Lc37DEbAAAAABy91EIWTie45JA9ddJ3SZ0Lk1ZB"
                    onChange={handleChangeRecaptcha}
                  />
                </Box>
                {isRecaptchaError ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center">
                    <Typography variant="body2" color="error">
                      Campo requerido
                    </Typography>
                  </Box>
                ) : (
                  <></>
                )}
              </Grid>
            ) : (
              <Box padding={3} />
            )}

            {!isInternal && personalization.instrucaoParaRodapeFormulario && (
              <Box
                width="100%"
                textAlign="center"
                dangerouslySetInnerHTML={{
                  __html:
                    personalization.instrucaoParaRodapeFormulario.replaceAll(
                      'class',
                      'className',
                    ),
                }}
              />
            )}

            {permissionEdit && (
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit">
                  Confirmar
                </Button>
              </Grid>
            )}
          </Grid>
        </Form>
      </Box>
    </Box>
  );
};
